const currently = {
    reading: [
        'Das Kapital 1, 2, 3',
        'Maths books from Uni',
        'Physics Fundamentals',
        'Socialism Utopian and Scientific',
    ],
    watching: {
        kdramas: [
            'My Name',
            'My First First Love',
            'Bloodhounds',
            'Squid Game Season 3',
        ],
        anime: [
            'FMA: Brotherhood',
        ],
    },
};

export default currently;