// src/data/gaming-videos.js
const gamingVideos = {
    'Wild Rift': [
        'https://bcnwhstmahslwfwoflpw.supabase.co/storage/v1/object/public/gaming-media/Weapons%20of%20Noxus%20.mp4',
        // Add more video links here
    ],
    'League of Legends': [
        // Add video links here
    ],
    'Teamfight Tactics': [
        // Add video links here
    ],
    'NARAKA BLADEPOINT': [
        // Add video links here
    ],
    'Monster Hunter World': [
        // Add video links here
    ],
};

export default gamingVideos;